import React, {useState, useEffect} from 'react';

function Login({ onLogin, isLoginFormActive }) {
    // Starea pentru username și password
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    // Starea pentru mesajele de eroare, indicatorul de loading și datele utilizatorului
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState(null);

    // Verificăm dacă există un token salvat în localStorage la încărcarea componentei
    useEffect(() => {
        const savedToken = localStorage.getItem('authToken');
        const savedUser = localStorage.getItem('userData');
        
        if (savedToken && savedUser) {
            try {
                const userData = JSON.parse(savedUser);
                setUser(userData);
                if (onLogin) {
                    onLogin(userData);
                }
            } catch (error) {
                console.error('Eroare la parsarea datelor utilizatorului:', error);
                // Ștergem datele invalide
                localStorage.removeItem('authToken');
                localStorage.removeItem('userData');
            }
        }
    }, [onLogin]);

    // Funcția care gestionează trimiterea formularului
    const handleSubmit = async (event) => {
        event.preventDefault();       // Previne reîncărcarea paginii
        setLoading(true);       // Setează loading-ul
        setError(null);         // Resetează eroarea

        // Validare simplă
        if (!email || !password) {
            setError('Toate câmpurile sunt obligatorii');
            setLoading(false);
            return;
        }

        // Validare format email
        if (!/\S+@\S+\.\S+/.test(email)) {
            setError('Email invalid');
            setLoading(false);
            return;
        }

        try {
            // Apelul către server
            const response = await fetch('https://backend.alephmedia.ro/api/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                // Trimitem datele de login în format JSON
                body: JSON.stringify({ email, password })
            });

            // Verificăm dacă răspunsul este ok
            if (!response.ok) {
                throw new Error('Autentificare eșuată. Verificați datele de conectare.');
            }

            // Preluăm datele din răspuns
            const data = await response.json();

            // Salvăm token-ul și datele utilizatorului în localStorage
            if (data.data && data.data.token) {
                localStorage.setItem('authToken', data.data.token);
                
                const userData = {
                    name: data.data.name,
                    email: data.data.email,
                    id: data.data.id
                };
                
                localStorage.setItem('userData', JSON.stringify(userData));
                
                // Setăm utilizatorul și apelăm callback-ul de login
                setUser(userData);
                if (onLogin) {
                    onLogin(userData);
                }
            }
        }
        catch (error) {
            // Dacă apare o eroare, o afișăm
            setError(error.message);
        }
        finally {
            // Oprește indicatorul de loading
            setLoading(false);
        }
    };

    // Dacă utilizatorul este logat, nu afișăm formularul
    if (user) {
        return null;
    }

    return (
        <div className="login-wrapper show">
            <div className="login-container">
                <p className="login-title">Login</p>

                {error && <p className="error">{error}</p>}
                {loading && <p className="loading">Se procesează...</p>}

                <form onSubmit={handleSubmit}>
                    <div className="form-row">
                        <input type="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)}
                               className="form-field" placeholder="email" autoComplete="email" required />
                    </div>
                    <div className="form-row">
                        <input type="password" id="password" value={password} onChange={(e) => setPassword(e.target.value)}
                               className="form-field" placeholder="parola" autoComplete="current-password" required />
                    </div>
                    <div className="form-row">
                        <button type="submit" className="form-submit" disabled={loading}>
                            {loading ? 'Se procesează...' : 'Autentificare'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default Login;