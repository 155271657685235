import React, { useContext, useEffect, useRef } from "react";
import DispatchContext from "../DispatchContext.js";
import StateContext from "../StateContext.js";
import Menu from "./Menu.js";
import Login from "./Login.js";

function Tray({ TrayContent }) {
  const dispatch = useContext(DispatchContext);
  const appState = useContext(StateContext);

  const trayRef = useRef(null);

  useEffect(() => {
    // Inițializăm tray-ul cu componenta Login la încărcarea aplicației
    if (appState.trayContent === null) {
      dispatch({ type: "setTrayContent", value: Login });
      if (trayRef.current) {
        trayRef.current.classList.add("initial");
      }
    }
  }, []);

  useEffect(() => {
    // Gestionăm secvența de afișare după autentificare
    if (appState.isLoggedIn && !appState.isTimerStopped) {
      // După login, afișăm imediat meniul cu sigla Aleph
      dispatch({ type: "setTrayContent", value: Menu });
      
      // După 3 secunde, schimbăm opacitatea tray-ului
      const timerBeginTransparency = setTimeout(() => {
        if (trayRef.current) {
          trayRef.current.classList.remove("initial");
        }
      }, 3000);
      
      // Schimbăm opacitatea elementelor din meniu
      const menuBeginTransparency = setTimeout(() => {
        if (trayRef.current) {
          const divElements = trayRef.current.querySelectorAll('.menu-column');
          divElements.forEach((div) => {
            div.classList.remove("initial");
          });
        }
      }, 4000);
      
      // După 2 secunde de inactivitate, retragem tray-ul
      const timerCloseInitialTray = setTimeout(() => {
        dispatch({ type: "setTrayVisible", value: false });
      }, 6000);
      
      return () => {
        clearTimeout(timerBeginTransparency);
        clearTimeout(menuBeginTransparency);
        clearTimeout(timerCloseInitialTray);
      };
    }
  }, [appState.isLoggedIn, appState.isTimerStopped, dispatch]);

  const renderTrayContent = () => {
    if (TrayContent) {
      if (TrayContent === Login) {
        return <Login 
          onLogin={(userData) => {
            dispatch({ type: "userLoggedIn", payload: userData });
          }} 
          isLoginFormActive={true} 
        />;
      } else if (typeof TrayContent === "function") {
        return <TrayContent />;
      }
      return <TrayContent />;
    }
    return null;
  };

  return (
    <div ref={trayRef} className={`tray ${appState.isTrayVisible ? "visible" : ""}`}>
      {renderTrayContent()}
    </div>
  );
}

export default Tray;
