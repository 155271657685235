import React, { useContext, useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faVolumeHigh,
  faVolumeMute,
  faBars,
  faUsers,
  faChevronLeft,
  faSignOutAlt
} from "@fortawesome/free-solid-svg-icons";

import StateContext from "../StateContext";
import DispatchContext from "../DispatchContext";
import Menu from "./Menu";
import Login from "./Login";

function Nav() {
  const appState = useContext(StateContext);
  const dispatch = useContext(DispatchContext);

  const toggleMuted = () => {
    dispatch({ type: "toggleMute" });
  };

  const handleTray = () => {
    if (appState.isTrayVisible === false) {
      dispatch({ type: "setTrayVisible", value: true });
    } else {
      if (appState.trayContent !== Menu) {
        dispatch({ type: "setTrayContent", value: Menu });
      } else {
        dispatch({ type: "setTrayVisible", value: false });
      }
    }
  };

  const handleLogout = () => {
    // Ștergem token-ul și datele utilizatorului din localStorage
    localStorage.removeItem('authToken');
    localStorage.removeItem('userData');
    
    // Actualizăm starea aplicației
    dispatch({ type: "userLoggedOut" });
    
    // Afișăm formularul de login
    dispatch({ type: "setTrayContent", value: Login });
    dispatch({ type: "setTrayVisible", value: true });
  };

  return (
    <>
      <nav className="nav">
        <ul className="icons">
          <li className="hamburger">
            <a onClick={handleTray}>
              <FontAwesomeIcon
                icon={
                  appState.trayContent === null || appState.trayContent === Menu
                    ? faBars
                    : faChevronLeft
                }
              />
            </a>
          </li>
          <li className="empty"></li>
          <li className="notification">
            {!appState.isTrayVisible && appState.isLoggedIn && (
              <img src="/assets/img/logo-aleph.svg" alt="Aleph" />
            )}
          </li>
          <li className="volume">
            <a onClick={toggleMuted}>
              <FontAwesomeIcon
                icon={appState.muted ? faVolumeHigh : faVolumeMute}
              />
            </a>
          </li>
          {appState.isLoggedIn ? (
            <li className="logout">
              <a onClick={handleLogout} title="Deconectare">
                <FontAwesomeIcon icon={faSignOutAlt} />
              </a>
            </li>
          ) : (
            <li className="users">
              <FontAwesomeIcon icon={faUsers} />
            </li>
          )}
        </ul>
      </nav>
    </>
  );
}

export default Nav;
